import legacySelector from './modules/legacy/index-selector';
import onekeySelector from './modules/onekey/index-selector';

// Load Standard Scripts.
import './modules';

// Load service worker in production build only.
// if (process.env.NODE_ENV === 'production') {
//   if ('serviceWorker' in navigator) {
//     navigator.serviceWorker.register('/sitefiles/dist/sw.js', { scope: '/' }).catch(err => {
//       // eslint-disable-next-line no-console
//       console.warn('Could not register service worker:', err);
//     });
//   }
// }

// Load Onekey Scripts.
const onekeyComponents = $(onekeySelector);
if (onekeyComponents.length > 0) {
  import(/* webpackChunkName: "one-key" */ './modules/onekey');
}

// Load Legacy Scripts.
const legacyComponents = $(legacySelector);
if (legacyComponents.length > 0) {
  import(/* webpackChunkName: "legacy" */ './modules/legacy');
}
