class DeviceDection {
  constructor() {
    this.html = document.querySelector('html');

    // Device Detection
    this.android = /(android)/i.test(navigator.userAgent);
    this.androidClass = 'android';
    //
    this.iOS = /iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    this.iOSClass = 'iOS';

    // Browser Detection
    this.isChrome = !!window.chrome;
    this.chromeClass = 'chrome';
    //
    this.isFirefox = typeof window.InstallTrigger !== 'undefined';
    this.firefoxClass = 'firefox';
    //
    this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    this.safariClass = 'safari';

    this.bindToHtml();
  }

  bindToHtml = () => {
    if (this.iOS) {
      this.html.classList.add(this.iOSClass);
    } else if (this.android) {
      this.html.classList.add(this.androidClass);
    }

    if (this.isChrome) {
      this.html.classList.add(this.chromeClass);
    } else if (this.isFirefox) {
      this.html.classList.add(this.firefoxClass);
    } else if (this.isSafari) {
      this.html.classList.add(this.safariClass);
    }
  };
}

$(document).ready(() => {
  // eslint-disable-next-line
  new DeviceDection();
});
