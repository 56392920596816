if (document.querySelector('.js-ho-nav')) {
  import(/* webpackChunkName: "high-output-navigation" */ './navigation');
}

if (document.querySelector('.js-ho-video')) {
  import(/* webpackChunkName: "high-output-videos" */ './videos');
}

if (document.querySelector('.js-ho-2-optimised-range')) {
  import(/* webpackChunkName: "high-output-optimised-range" */ './optimised-range');
}

if (document.querySelector('.js-landing-banner-video, .js-landing-icon-text')) {
  import(/* webpackChunkName: "high-output-landing-banner" */ './landing-banner');
}
