// Load performance.
import './performance';

// Load analytics
import './analytics';

// Utils
import './utils/device-detection';

// Load standard components.
import './content-loaded';
import './custom-vh';

// These components have dynamic imports in the index files.
import './trades';
import './high-output-2019';

// Dynamic imports
if (document.querySelector('picture')) {
  import(/* webpackChunkName: "picturefill" */ 'picturefill/dist/picturefill');
}

if (document.querySelector('.js-home-slick')) {
  import(/* webpackChunkName: "slick-home" */ './call-slick-home');
}

if (document.querySelector('.js-ds-icon-bar')) {
  import(/* webpackChunkName: "icon-bar" */ './slick-carousels/icon-bar');
}

if (
  document.querySelector(
    // eslint-disable-next-line max-len
    '.onekey-landing, .js-carousel-m18, .js-carousel-m18-red, .js-carousel, .js-carousel-tracking, .js-slider-nav, .js-carousel-reports, .js-slider-nav-reports, .js-carousel-content, .js-support-carousel, .js-support-tabs, .js-carousel-recommended, .js-carousel-heated-gear',
  )
) {
  import(/* webpackChunkName: "legacy-slick-carousels" */ './slick-carousels/legacy-carousels');
}

if (document.querySelector('.js-pr-carousel')) {
  import(/* webpackChunkName: "press-release-carousel" */ './slick-carousels/press-release');
}

if (
  document.querySelector(
    '.js-product-detail-carousel, .js-product-nav-carousel, .js-recommended-carousel, .js-reviews-carousel, .js-variant-selection',
  )
) {
  import(/* webpackChunkName: "product-carousels" */ './slick-carousels/product-carouels');
}

if (document.querySelector('.js-checkbox, .js-checkbox-text')) {
  import(/* webpackChunkName: "checkboxes-legacy" */ './init-checkbox');
}

if (document.querySelector('input[type=checkbox]')) {
  import(/* webpackChunkName: "checkboxes" */ './call-checkboxes');
}

if (document.querySelector('select:not(.cs-select):not(.chosen-ignore):not(.js-chosen-ignore)')) {
  import(/* webpackChunkName: "chosen" */ './call-chosen');
}

if (document.querySelector('#win-terms-text')) {
  import(/* webpackChunkName: "terms-win-text" */ './terms');
}

if (document.querySelector('.js-reviews-section, .js-description-expand, #productFactTags')) {
  import(/* webpackChunkName: "product-features" */ './product-features');
}

if (document.querySelector('.js-cookie-consent, .js-cookies-allow')) {
  import(/* webpackChunkName: "cookie-popup" */ './cookie-popup');
}

if (document.querySelector('.table.responsive')) {
  import(/* webpackChunkName: "responsive-tables" */ './responsive-tables');
}

if (document.querySelector('.placeholder label')) {
  import(/* webpackChunkName: "placeholder" */ './placeholder');
}

if (document.querySelector('.card--columned a')) {
  import(/* webpackChunkName: "card-columned" */ './column-cards');
}

if (document.querySelector('.js-automated-tool-carousel')) {
  import(/* webpackChunkName: "automated-tool-carousel" */ './call-slick-automated-tools');
}

if (document.querySelector('.js-img-reel-carousel')) {
  import(/* webpackChunkName: "slick-system-video" */ './call-slick-system-video');
}

if (
  document.querySelector('.js-ds-product-carousel') ||
  document.querySelector('.js-ds-feature-product-carousel')
) {
  import(/* webpackChunkName: "ds-product-carousel" */ './call-slick-product-carousel');
}

if (document.querySelector('.price-spider')) {
  import(/* webpackChunkName: "price-spider" */ './price-spider');
}

if (document.querySelector('.js-responsive-table')) {
  import(/* webpackChunkName: "responsive-table" */ './responsive-table');
}

if (document.querySelector('.js-product-videos-popup')) {
  import(/* webpackChunkName: "product-video-popup" */ './init-product-video-popup');
}

if (document.querySelector('.js-mobile-popup-banner')) {
  import(/* webpackChunkName: "mobile-popup-banner" */ './init-mobile-popup-banner');
}

if (document.querySelector('.js-tick-competition')) {
  import(/* webpackChunkName: "tick-competition" */ './init-tick-competition');
}

if (document.querySelector('.countdown-container')) {
  import(/* webpackChunkName: "countdown" */ './init-countdown');
}

if (document.querySelector('.js-redirect-url')) {
  import(/* webpackChunkName: "redemption-form" */ './init-redemption-form');
}

if (document.querySelector('.js-hdn-redirect')) {
  import(/* webpackChunkName: "redirects" */ './init-redirect');
}

if (document.querySelector('.js-doi-popup')) {
  import(/* webpackChunkName: "doi-popup" */ './init-doi-questionnaire');
}

if (document.querySelector('.js-system-navigation')) {
  import(/* webpackChunkName: "systems-navigation" */ './init-system-navigation');
}

if (document.querySelector('.js-scroll-to-listing')) {
  import(/* webpackChunkName: "product-listing-scroll" */ './init-product-listing-scroll');
}

if (document.querySelector('.js-video-seeker-carousel')) {
  import(/* webpackChunkName: "video-seeker-carousel" */ './init-video-seeker-carousel');
}

if (document.querySelector('.js-step-1, .js-step-2, .js-step-3')) {
  import(/* webpackChunkName: "crack-the-vault" */ './crack-the-vault');
}

if (document.querySelector('.js-redemption-form')) {
  import(/* webpackChunkName: "redemption-form" */ './kickstart-redemption');
}

if (document.querySelector('.js-scrollbar')) {
  import(/* webpackChunkName: "scrollbar" */ './2019/init-scrollbar');
}

if (document.querySelector('.js-ds-pin-container')) {
  import(/* webpackChunkName: "pin-banner" */ './2019/pin-banner');
}

if (document.querySelector('.js-ds-read-more')) {
  import(/* webpackChunkName: "inner-banner" */ './2019/init-inner-banner');
}

if (document.querySelector('.js-card-applications-container')) {
  import(/* webpackChunkName: "card-applications" */ './init-product-card-applications');
}

if (document.querySelector('.js-scroll-to-top')) {
  import(/* webpackChunkName: "scrollToTop" */ './init-scroll-top');
}

if (document.querySelector('.js-ds-video-banner')) {
  import(/* webpackChunkName: "ds-video-banner" */ './2019/init-video-banner');
}

if (document.querySelector('.js-mips')) {
  import(/* webpackChunkName: "mips-banner" */ './2019/init-mips-banner');
}

if (document.querySelector('.js-ds-seo-carousel')) {
  import(/* webpackChunkName: "ds-seo-carousel" */ './2019/init-seo-carousel');
}

if (document.querySelector('.js-object-fit')) {
  import(/* webpackChunkName: "object-fit" */ './init-object-fit');
}

if (document.querySelector('.js-accordion')) {
  import(/* webpackChunkName: "accordion" */ './init-accordion');
}

if (document.querySelector('.js-popup')) {
  import(/* webpackChunkName: "popup" */ './init-popup');
}
