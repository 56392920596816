if (
  document.querySelectorAll(
    '.js-trades-landing-title, .js-image-container, .js-content-overlay, .js-content-block',
  ).length
) {
  import(/* webpackChunkName: "trades-banner" */ './trades-banner');
}

if (document.querySelector('.js-trades-sticky-nav')) {
  import(/* webpackChunkName: "trades-sticky-nav" */ './sticky-nav');
}
