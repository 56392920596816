import cssVars from 'css-vars-ponyfill';
import debounce from 'lodash/debounce';
import whenDomReady from 'when-dom-ready';

whenDomReady().then(() => {
  // Call using defaults
  cssVars();

  window.addEventListener('resize', debounce(setActualVhValue, 100));
  setActualVhValue();
});

function setActualVhValue() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}
